import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useWebApp } from "./hooks/useWebApp";
import ReactGA from "react-ga4";
import { WebAppContext } from "./contexts/WebAppContexts";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
// import General from "./pages/General";
import SelectCoin from "./pages/SelectCoin";
import Receive from "./pages/Receive";
import ComingSoon from "./pages/ComingSoon";
import Profile from "./pages/Profile";
import SeedPhrase from "./pages/SeedPhrase";
import Login from "./pages/Login";
import { CookiesProvider } from "react-cookie";
import Quests from "./pages/Quests";
import FAQ from "./pages/FAQ/idnex";
import PageTemplate from "./components/PageTemplate";
import Frens from "./pages/Frens";
import UnderMaintenance from "./pages/UnderMaintenance";
import {
  BETA_TESTERS,
  GA_TRACKING_ID,
  IsUnderMaintenance,
} from "./constants.tsx";
import { QueryClient, QueryClientProvider } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import Reward from "./pages/Reward/index.tsx";
import GeneralOrQuests from "./pages/GeneralOrQuests";
import Lootbox from "./pages/Lootbox/index.tsx";
// import Lottery from "./pages/Lottery/index.tsx";
import BuyLottery from "./pages/BuyLottery/index.tsx";
import Wallets from "./pages/Wallets";
// import VPN from "./pages/VPN/index.tsx";
import Subscription from "./pages/Subscription/index.tsx";
// import EarnPageTemplate from "./components/EarnPageTemplate/index.tsx";
import { useEffect } from "react";
import VPN from "./pages/VPN";
import Leaderboard from "./pages/Leaderboard";
import ErrorPage from "./pages/ErrorPage/index.tsx";
import { SpotAdsProvider } from "spot-ads-react";
// import Leaderboard from "./pages/Leaderboard";

function App() {
  const webApp = useWebApp();
  const queryClient = new QueryClient();
  const isBetaTester = BETA_TESTERS.includes(
    webApp?.initDataUnsafe?.user?.id || -1
  );
  console.log(isBetaTester, webApp?.initDataUnsafe?.user?.id, BETA_TESTERS);
  useEffect(() => {
    ReactGA.initialize(GA_TRACKING_ID);
    // ReactGA.send({ hitType: "pageview", page: "/landingpage", title: "Landing Page" });
  }, []);

  /*
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams();
  const hasForceQuest = (searchParams.get("force_quest_id") !== null);
  console.log("HasForce Quest: ", hasForceQuest);
  */
  if (IsUnderMaintenance) return <UnderMaintenance />;

  return (
    <TonConnectUIProvider
      manifestUrl="https://web.dormint.io/tonconnect-manifest.json"
      uiPreferences={{ theme: THEME.DARK }}
      walletsListConfiguration={{
        includeWallets: [
          {
            appName: "tonwallet",
            name: "TON Wallet",
            imageUrl: "https://wallet.ton.org/assets/ui/qr-logo.png",
            aboutUrl:
              "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
            universalLink: "https://wallet.ton.org/ton-connect",
            jsBridgeKey: "tonwallet",
            bridgeUrl: "https://bridge.tonapi.io/bridge",
            platforms: ["chrome", "android"],
          },
        ],
      }}
    >
      <SpotAdsProvider apiKey="">
        <WebAppContext.Provider value={{ WebApp: webApp }}>
          <QueryClientProvider client={queryClient}>
            <CookiesProvider defaultSetOptions={{ path: "/" }}>
              <BrowserRouter>
                <ErrorBoundary FallbackComponent={UnderMaintenance}>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route element={<PageTemplate />}>
                      <Route
                        path="/"
                        element={
                          IsUnderMaintenance ? (
                            <UnderMaintenance />
                          ) : (
                            <GeneralOrQuests />
                          )
                        }
                      />
                      <Route path="/selectCoin" element={<SelectCoin />} />
                      <Route path="/receive" element={<Receive />} />
                      <Route path="/soon" element={<ComingSoon />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/seedPhrase" element={<SeedPhrase />} />
                      <Route path="/faq" element={<FAQ />} />
                      <Route path="/frens" element={<Frens />} />
                      <Route path="/reward" element={<Reward />} />
                      <Route path="/earn/leaders" element={<Leaderboard />} />
                      <Route path="/earn/lottery" element={<ComingSoon />} />
                      <Route
                        path="/earn/lottery/buy"
                        element={<BuyLottery />}
                      />
                      <Route path="/earn/lootbox" element={<Lootbox />} />
                      <Route path="/wallet" element={<Wallets />} />
                      <Route path="/earn/tasks" element={<Quests />} />
                      <Route path="/vpn" element={<VPN />} />
                      <Route path="/subscription" element={<Subscription />} />
                    </Route>
                    <Route path="/error" element={<ErrorPage />} />
                  </Routes>
                </ErrorBoundary>
              </BrowserRouter>
            </CookiesProvider>
          </QueryClientProvider>
        </WebAppContext.Provider>
      </SpotAdsProvider>
    </TonConnectUIProvider>
  );
}

export default App;

import { useContext, useEffect, useState } from "react";
import styles from "./StarsQuestCard.module.scss";
import GreeenDoneIcon from "../../assets/icons/GreenDoneIcon";
import RedErrorIcon from "../../assets/icons/RedErrorIcon.tsx";
import axios from "axios";
import { API } from "../../constants.tsx";
import { useAuthToken } from "../../hooks/useAuthToken.tsx";
import { WebAppContext } from "../../contexts/WebAppContexts.tsx";
import { useNavigate } from "react-router-dom";
// import CircularProgress from "@mui/joy/CircularProgress";

const StarGramLogo = ({ width, height }: { width: number; height: number }) => {
  return (
    <svg height={height} width={width} viewBox="0 0 47.94 47.94">
      <path
        style={{ fill: "#ED8A19" }}
        d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757
            c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042
            c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685
            c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528
            c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956
            C22.602,0.567,25.338,0.567,26.285,2.486z"
      />
    </svg>
  );
};

function StarsQuestCard({ starsType }: { starsType: string }) {
  const authToken = useAuthToken();
  const { WebApp } = useContext(WebAppContext);
  const navigate = useNavigate();
  const [newCompletedQuest, setNewCompletedQuest] = useState<boolean>(false);
  const [errorCompletedQuest, setErrorCompletedQuest] =
    useState<boolean>(false);

  const [showAlert, setShowAlert] = useState(false);
  const [animate, setAnimate] = useState<"open" | "close">("open");
  const [error, setError] = useState(false);

  const openPaymentLink = (invoiceType: string) => {
    axios
      .post(`${API}/tg/donate/invoice`, {
        auth_token: authToken,
        invoiceType: invoiceType,
      })
      .then((resp) => {
        if (resp.data.status === "ok") {
          WebApp?.openTelegramLink(resp.data.invoiceLink);
          // WebApp?.openLink(resp.data.invoiceType, "_blank");
          console.log("Invoice", resp.data.invoiceLink);
          setTimeout(() => {
            navigate(`/`);
          }, 3000);
          // WebApp?.close();
        } else {
          window.location.reload();
        }
      })
      .catch(() => {
        navigate("/error");
      });
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setAnimate("close");
      }, 2000);
      setTimeout(() => {
        setError(false);
        window.location.reload();
      }, 2500);
    }
  }, [error]);

  useEffect(() => {
    if (newCompletedQuest) {
      setShowAlert(true);
      setTimeout(() => {
        setAnimate("close");
      }, 2000);
      setTimeout(() => {
        setShowAlert(false);
        setNewCompletedQuest(false);
      }, 2500);
    }
  }, [newCompletedQuest]);

  useEffect(() => {
    if (errorCompletedQuest) {
      setShowAlert(true);
      setTimeout(() => {
        setAnimate("close");
      }, 3500);
      setTimeout(() => {
        setShowAlert(false);
        setErrorCompletedQuest(false);
      }, 3500);
    }
  }, [errorCompletedQuest]);

  // const isBetaTester = BETA_TESTERS.includes(WebApp?.initDataUnsafe.user.id || -1);
  return (
    <>
      {error && (
        <div
          className={
            styles[`${animate === "open" ? "alert_open" : "alert_close"}`]
          }
        >
          <RedErrorIcon />
          Something went wrong!
        </div>
      )}
      {showAlert && newCompletedQuest && (
        <div
          className={
            styles[`${animate === "open" ? "alert_open" : "alert_close"}`]
          }
        >
          <GreeenDoneIcon />
          You received{" "}
          {starsType === "super_strong"
            ? "+60 000 SC"
            : starsType === "strong"
            ? "+12 500 SC"
            : "+1 250 SC"}{" "}
          Sleepcoins!
        </div>
      )}
      {showAlert && errorCompletedQuest && (
        <div
          className={
            styles[`${animate === "open" ? "alert_open" : "alert_close"}`]
          }
        >
          <RedErrorIcon />
          You haven't completed the quest, please try again :(
        </div>
      )}
      <div
        className={styles.questDiv}
        onClick={() => {
          openPaymentLink(starsType);
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <div className={styles.questInfo}>
          <div className={styles.imgContainer}>
            <StarGramLogo width={20} height={20} />
          </div>
          <div className={styles.textDiv}>
            {starsType === "super_strong"
              ? "Become the Dormint's Superstar!"
              : starsType === "strong"
              ? "Light up the Stars in Dormint"
              : "Catch the Star"}
          </div>
        </div>
        <div className={styles.questInfo}>
          <span
            style={{
              whiteSpace: "nowrap",
              color: "#fff",
            }}
          >
            {starsType === "super_strong"
              ? "+60 000 SC"
              : starsType === "strong"
              ? "+12 500 SC"
              : "+1 250 SC"}
          </span>
          <div className={styles.circle} />
        </div>
      </div>
    </>
  );
}

export default StarsQuestCard;

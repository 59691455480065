import { useContext, useEffect, createRef, useState } from "react";
import lootbox_closed from "../../assets/images/lootbox_closed.png";
import mini_coin from "../../assets/images/mini-coin.png";
import styles from "./Lootbox.module.scss";
import EarnNavigationBar from "../../components/EarnNavigationBar";
import earn_bg from "../../assets/images/big_waves.png";
import OpenedLootbox from "../../components/OpenedLootbox";
import LootboxIcon from "../../assets/icons/LootboxIcon";
import {
  API,
  BOT_NAME,
  coinIcon,
  NOT_ENOUGH_DAILY_QUOTE_LOOTBOXES_ERROR,
  NOT_ENOUGH_SLEEPCOINS_ERROR,
} from "../../constants";
import FriendlyPillows from "../../assets/images/friendly_pillows.png";
import Copy from "../../assets/icons/Copy";
import Gift from "../../assets/images/gift.png";
import { useAuthToken } from "../../hooks/useAuthToken.tsx";
import axios from "axios";
import { WebAppContext } from "../../contexts/WebAppContexts.tsx";
import {
  DataState,
  sleepcoinBalanceVerification,
  tonBalanceVerification,
} from "../../store/slices/data.ts";
import store, { RootState } from "../../store";
import RedErrorIcon from "../../assets/icons/RedErrorIcon.tsx";
import GreeenDoneIcon from "../../assets/icons/GreenDoneIcon.tsx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Lootbox = () => {
  const { WebApp } = useContext(WebAppContext);
  const data = useSelector<RootState>((state) => state.data) as DataState;
  const navigate = useNavigate();

  // const data = useSelector<RootState>((state) => state.data) as DataState;
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [animateCopyAlert, setAnimateCopyAlert] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const [boughtItem, setBoughtItem] = useState(null);
  const authToken = useAuthToken();
  const [lootboxCount, setLootboxCount] = useState(0);
  const [lootboxBySleepcoinsLeft, setLootboxBySleepcoinsLeft] = useState(0);
  const [inviteToken, setInviteToken] = useState(null);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setAnimateCopyAlert(false);
      }, 2000);
      setTimeout(() => {
        setError(false);
      }, 2500);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setAnimateCopyAlert(false);
      }, 2000);
      setTimeout(() => {
        setSuccess(false);
      }, 2500);
    }
  }, [success]);

  function handleShowCopyAlert() {
    setAnimateCopyAlert(true);
    setShowCopyAlert(true);
    setTimeout(() => {
      setAnimateCopyAlert(false);
    }, 1500);
    setTimeout(() => {
      setShowCopyAlert(false);
    }, 2000);
  }

  const getStatus = (auth_token: string) => {
    axios
      .post(`${API}/tg/lootboxes/status`, {
        auth_token: auth_token,
      })
      .then((resp) => {
        if (resp.data.status === "ok") {
          setLootboxCount(resp.data.lootboxCount);
          setInviteToken(resp.data.inviteToken);
          setLootboxBySleepcoinsLeft(resp.data.lootboxBySleepcoinsLeft);
          store.dispatch(
            sleepcoinBalanceVerification(resp.data.sleepcoinBalance)
          );
          store.dispatch(tonBalanceVerification(resp.data.tonBalance));
        }
      })
      .catch(() => {
        navigate("/error");
      });
  };
  useEffect(() => {
    if (authToken === null) return;
    getStatus(authToken);
  }, [authToken]);

  const openPaymentLink = (invoiceType: string) => {
    axios
      .post(`${API}/tg/payment/invoice`, {
        auth_token: authToken,
        invoiceType: invoiceType,
      })
      .then((resp) => {
        if (resp.data.status === "ok") {
          WebApp?.openTelegramLink(resp.data.invoiceLink);
          console.log("Invoice", resp.data.invoiceLink);
          setTimeout(() => {
            getStatus(authToken!);
          }, 3000);
          // WebApp?.close();
        } else {
          window.location.reload();
        }
      })
      .catch(() => {
        navigate("/error");
      });
  };

  function generateInviteUrl() {
    return `https://t.me/${BOT_NAME}/${BOT_NAME}?startapp=${inviteToken}`;
  }

  const buyLootboxBySleepcoins = () => {
    setLootboxBySleepcoinsLeft((prev) => prev - 1);
    axios
      .post(`${API}/tg/lootboxes/buy/sleepcoins`, {
        auth_token: authToken,
      })
      .then((resp) => {
        if (resp.data.status === "ok") {
          setLootboxCount(resp.data.lootboxCount);
          store.dispatch(
            sleepcoinBalanceVerification(resp.data.sleepcoinBalance)
          );
          setSuccess(true);
          setMessage("You've successfully bought a Lootbox!");
        } else {
          setError(true);
          if (
            resp.data.status_code === NOT_ENOUGH_DAILY_QUOTE_LOOTBOXES_ERROR
          ) {
            setMessage(
              "You can purchase only 3 Lootboxes per day using Sleepcoins!"
            );
          } else if (resp.data.status_code === NOT_ENOUGH_SLEEPCOINS_ERROR) {
            setMessage("Not enough sleepcoins :(");
          } else {
            setMessage("Something went wrong...");
          }
        }
      })
      .catch(() => {
        navigate("/error");
      });
  };

  const openLootbox = () => {
    axios
      .post(`${API}/tg/lootboxes/open`, {
        auth_token: authToken,
      })
      .then((resp) => {
        if (resp.data.status == "ok") {
          setBoughtItem(resp.data.lootboxPrize);
          store.dispatch(
            sleepcoinBalanceVerification(resp.data.sleepcoinBalance)
          );
          store.dispatch(tonBalanceVerification(resp.data.tonBalance));
          if (resp.data.lootboxPrize.prize_type === "lootboxes") {
            setLootboxCount((prev) => prev - 1 + resp.data.lootboxPrize.amount);
          } else {
            setLootboxCount((prev) => prev - 1);
          }
          setTimeout(() => {
            setBoughtItem(null);
          }, 3000);
        } else {
          window.location.reload();
        }
      })
      .catch(() => {
        navigate("/error");
      });
  };

  const buttonRef = createRef<HTMLButtonElement>();
  const [textDivTop, setTextDivTop] = useState(0);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if (buttonRef.current) {
      setTextDivTop(
        buttonRef.current.offsetTop + buttonRef.current.offsetHeight + 10
      );
    }
  }, []);
  useEffect(() => {
    if (showInfo) {
      setTimeout(() => {
        setShowInfo(false);
      }, 2000);
    }
  }, [showInfo]);
  return (
    <>
      {error && (
        <div
          className={
            styles[`${animateCopyAlert ? "alert_open" : "alert_close"}`]
          }
        >
          <RedErrorIcon />
          {message}
        </div>
      )}
      {success && (
        <div
          className={
            styles[`${animateCopyAlert ? "alert_open" : "alert_close"}`]
          }
        >
          <GreeenDoneIcon />
          {message}
        </div>
      )}
      {showCopyAlert && (
        <div
          className={
            styles[`${animateCopyAlert ? "alert_open" : "alert_close"}`]
          }
        >
          Invite link copied!
        </div>
      )}
      <div className={styles.lootboxPage}>
        <EarnNavigationBar />
        {/* <div
          style={{
            marginTop: "70px",
            padding: "15px",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          {data.sleepcoinBalance !== null && (
            <div className={styles.userBalance}>
              {coinIcon("Sleepcoin", "22px", "22px")}
              <div className={styles.balance}>
                {data.sleepcoinBalance.toFixed(1)}
              </div>
            </div>
          )}
          {data.tonBalance !== null && (
            <div className={styles.userBalance}>
              {coinIcon("TON", "22px", "22px")}
              <div className={styles.balance}>{data.tonBalance.toFixed(1)}</div>
            </div>
          )}
        </div> */}
        <div className={styles.content}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "6px",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "16px",
            }}
          >
            <button
              className={styles.lootbox_buy_button}
              style={{
                background: "#494C78",
                borderRadius: "16px",
                padding: "6px 20px",
              }}
            >
              <div>
                {coinIcon("minicoin", "12px", "12px")}
                <span>
                  {data.sleepcoinBalance !== null &&
                    data.sleepcoinBalance.toFixed(1)}
                </span>
              </div>
            </button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                flexDirection: "row",
              }}
            >
              <button
                className={styles.lootbox_buy_button}
                style={{
                  borderRadius: "16px",
                  padding: "6px 20px",
                  opacity: data.tonBalance && data.tonBalance > 1 ? 1 : 0.2,
                }}
                onClick={() => {
                  data.tonBalance !== null &&
                    data.tonBalance > 1 &&
                    navigate("/wallet");
                }}
              >
                <div>
                  {data.tonBalance !== null && data.tonBalance > 0 && (
                    <span>CLAIM</span>
                  )}
                  {coinIcon("toncoin", "12px", "12px")}
                  <span>
                    {data.tonBalance !== null && data.tonBalance.toFixed(1)}
                  </span>
                </div>
              </button>
              <button
                className={styles.lootbox_buy_button}
                style={{ borderRadius: "16px", padding: "6px 11.5px" }}
                ref={buttonRef}
                onClick={() => {
                  setShowInfo(true);
                }}
              >
                <div>
                  <span>?</span>
                </div>
              </button>
              <div
                className={styles.infoAlert}
                style={{
                  opacity: showInfo ? 1 : 0,
                  top: textDivTop,
                  right: "18px",
                }}
              >
                Dormint LootBoxes: open to win epic prizes!
                <br />
                3 LootBoxes for Sleepcoins daily!
                <br />
                Get a free LootBox for inviting a friend.
                <br />
                Use 5 Telegram Stars to buy 1 LootBox.
                <br />
                Use 20 Telegram Stars to buy 5 LootBoxes with a 20% discount!
              </div>
            </div>
          </div>
          <img
            src={earn_bg}
            alt="bg"
            style={{
              width: "100vw",
              position: "absolute",
              top: "-35px",
              zIndex: -1,
            }}
          />
          {!boughtItem ? (
            <div className={styles.lootbox_content}>
              <img
                src={lootbox_closed}
                alt="lootbox_closed"
                style={{ width: "50vw", height: "50vw" }}
              />
              <h3>Let’s see how lucky you are today!</h3>
              <p>
                Up to <span>3 LootBoxes for Sleepcoins</span> daily!
              </p>
              <div
                style={{
                  position: "relative",
                  width: "100vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className={styles.lootbox_buy_button}
                  style={{
                    width: "50vw",
                    height: "52px",
                    opacity: lootboxCount > 0 ? 1 : 0.2,
                  }}
                  onClick={openLootbox}
                  disabled={lootboxCount <= 0}
                >
                  <span>Open Lootbox</span>
                </button>
                <div className={styles.lootbox}>
                  <LootboxIcon width={20} height={20} />
                  <span>{lootboxCount}</span>
                </div>
              </div>
              <div className={styles.buttonsBox}>
                <button
                  className={styles.lootbox_buy_button}
                  onClick={() => {
                    openPaymentLink("little");
                    // setIsBought(true)
                  }}
                >
                  <span>Buy </span>
                  <div>
                    {coinIcon("tgstar", "12px", "12px")}
                    <span>5 stars</span>
                  </div>
                </button>
                <button
                  className={styles.lootbox_buy_button}
                  onClick={() => {
                    if (lootboxBySleepcoinsLeft) {
                      buyLootboxBySleepcoins();
                    }
                  }}
                  disabled={lootboxBySleepcoinsLeft <= 0}
                  style={{
                    display: "block",
                    opacity: lootboxBySleepcoinsLeft <= 0 ? 0.2 : 1,
                  }}
                >
                  <div>
                    <span>Buy </span>
                    <img
                      src={mini_coin}
                      alt="mini coin"
                      width={10}
                      height={10}
                    />
                    <span>500 SC</span>
                    <br />
                  </div>
                  <div>
                    <span
                      style={{ fontSize: "10px" }}
                    >{`(${lootboxBySleepcoinsLeft} left today*)`}</span>
                  </div>
                </button>
                <button
                  className={styles.lootbox_buy_button}
                  onClick={() => {
                    openPaymentLink("lot");
                  }}
                >
                  <span>Buy 5 Lootboxes </span>
                  <div>
                    {coinIcon("tgstar", "12px", "12px")}
                    <span> 20 stars</span>
                  </div>
                </button>
              </div>
              <div className={styles.inviteBox}>
                <div className={styles.inviteBoxTitle}>
                  <img src={FriendlyPillows} height="30px" />
                  <span>Invite a friend and get a Free Lootbox!</span>
                </div>
                <div
                  className={styles.copyIconBox}
                  onClick={() => {
                    if (inviteToken) {
                      handleShowCopyAlert();
                      navigator.clipboard.writeText(generateInviteUrl());
                    }
                  }}
                >
                  <Copy color="#fff" width="18px" height="18px" />
                </div>
              </div>
              <div className={styles.rewardsBox}>
                <h3>Available rewards</h3>
                <div>
                  <div
                    style={{ flexDirection: "row", justifyContent: "center" }}
                  >
                    <img src={Gift} width="16px" height="16px" />
                    <span>1 - 3 Lootboxes </span>
                  </div>
                  <div>
                    {coinIcon("minicoin", "14px", "14px")}
                    <span>1500 SC</span>
                  </div>
                  <div>
                    {coinIcon("minicoin", "14px", "14px")}
                    <span>1000 SC</span>
                  </div>
                  <div>
                    {coinIcon("minicoin", "14px", "14px")}
                    <span>500 SC</span>
                  </div>
                  <div>
                    {coinIcon("toncoin", "14px", "14px")}
                    <span>0.5 TON</span>
                  </div>
                  <div></div>
                  <div>
                    {coinIcon("toncoin", "14px", "14px")}
                    <span>0.1 TON</span>
                  </div>
                  <div>
                    {coinIcon("toncoin", "14px", "14px")}
                    <span>0.01 TON</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <OpenedLootbox data={boughtItem} />
          )}
        </div>
      </div>
    </>
  );
};

export default Lootbox;

import ErrorPillow from "../../assets/images/error_pillow.png";
import Waves from "../../assets/images/waves.png";
import styles from "./ErrorPage.module.scss";

function ErrorPage() {
  return (
    <div className={styles.errorContainer}>
      <div className={styles.imgsContainer}>
        <img className={styles.wavesImg} src={Waves} alt="waves" />
        <img className={styles.errorImg} src={ErrorPillow} alt="Error" />
      </div>
      <h1 className={styles.title}>Something went wrong</h1>
      <p className={styles.description}>
        Please disable your <span>ad blocker</span> or change your{" "}
        <span>VPN</span>
      </p>
    </div>
  );
}

export default ErrorPage;

import styles from "./Wallets.module.scss";
import walletsIcon from "../../assets/images/wallets.png";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import WalletCard from "../../components/WalletCard";
import { useEffect, useState } from "react";
import RedErrorIcon from "../../assets/icons/RedErrorIcon.tsx";
import PageHeader from "../../components/PageHeader/index.tsx";
import DormintWalletCard from "../../components/DormintWalletCard";
import { useAuthToken } from "../../hooks/useAuthToken.tsx";
import axios from "axios";
import { API } from "../../constants.tsx";
import { toUserFriendlyAddress } from "@tonconnect/sdk";
import { useSelector } from "react-redux";
import { RootState, store } from "../../store";
import { DataState, tonBalanceVerification } from "../../store/slices/data.ts";
import { useNavigate } from "react-router-dom";

function Wallets() {
  const authToken = useAuthToken();
  const data = useSelector<RootState>((state) => state.data) as DataState;

  const [error, setError] = useState(false);
  const [animate, setAnimate] = useState<"open" | "close">("open");
  // const [tonBalance, setTonBalance] = useState<number | null>(null);
  const [tonWalletConnected, setTonWalletConnected] = useState<boolean | null>(
    null
  );

  const [tonConnectUi] = useTonConnectUI();
  const wallet = useTonWallet();

  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      return;
    }
    if (data.tonBalance === null) {
      axios
        .post(`${API}/tg/wallet/status`, {
          auth_token: authToken,
        })
        .then((resp) => {
          store.dispatch(tonBalanceVerification(resp.data.tonBalance));
          setTonWalletConnected(resp.data.tonWalletConnected);
        })
        .catch(() => {
          navigate("/error");
        });
    }
  }, [authToken]);

  useEffect(() => {
    if (!authToken) {
      return;
    }
    if (tonWalletConnected !== null && !tonWalletConnected && wallet) {
      axios
        .post(`${API}/tg/wallet/connect`, {
          auth_token: authToken,
          tonAddress: toUserFriendlyAddress(wallet.account.address),
        })
        .then((resp) => {
          if (resp.data.status === "ok") {
            setTonWalletConnected(true);
          } else {
            window.location.reload();
          }
        })
        .catch(() => {
          navigate("/error");
        });
    }
  }, [authToken, tonWalletConnected, wallet]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setAnimate("close");
      }, 2000);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  }, [error]);

  return (
    <>
      <div className={styles.frensPage}>
        <PageHeader />
        <div className={styles.content} id="scrollableDiv">
          {error && (
            <div
              className={
                styles[`${animate === "open" ? "alert_open" : "alert_close"}`]
              }
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <RedErrorIcon />
              Now we can add only 1 wallet
            </div>
          )}
          <img
            src={walletsIcon}
            alt="FAQ"
            style={{ zIndex: "2", height: "auto", width: "60vw" }}
          />
          {!wallet && (
            <div
              style={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => {
                  if (wallet) {
                    setError(true);
                  } else {
                    tonConnectUi.openModal();
                  }
                }}
                className={styles.farmingButton}
                style={{
                  color: "#fff",
                  background:
                    "linear-gradient(180deg, #6B7AFF 0%, #4940F2 100%)",
                }}
              >
                <span>Connect TON wallet</span>
              </button>
            </div>
          )}
          <DormintWalletCard
            canWithdraw={!!wallet && !!data.tonBalance && data.tonBalance > 1}
            whyCantWithdraw={
              wallet
                ? "TON Balance must be greater 1"
                : "Connect wallet firstly"
            }
            tonBalance={data.tonBalance}
          />
          {wallet && (
            <WalletCard
              wallet={wallet}
              tonConnectUI={tonConnectUi}
              onDisconnectWallet={async () => {
                await axios
                  .post(`${API}/tg/wallet/disconnect`, {
                    auth_token: authToken,
                  })
                  .catch(() => {
                    navigate("/error");
                  });
                setTonWalletConnected(false);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Wallets;

import { ReactElement } from "react";
import Dorm from "./assets/icons/Dorm";
import Sleepcoin from "./assets/images/sleepcoin.png";
import Matic from "./assets/icons/Matic";
import DollarCoin from "./assets/images/dollar_coin.png";
import KingPlaceIcon from "./assets/images/king_place_icon.png";
import FirstPlaceIcon from "./assets/images/first_place_icon.png";
import SecondPlaceIcon from "./assets/images/second_place_icon.png";
import ThirdPlaceIcon from "./assets/images/third_place_icon.png";
import CryptoJS from "crypto-js";
import TonCoin from "./assets/images/toncoin.png";
import TgStar from "./assets/images/tgstar.png";
import MiniCoin from "./assets/images/mini-coin.png";
import LootBox from "./assets/images/lootbox_closed.png";

export const IS_RELEASE = true;

export const API = IS_RELEASE
  ? "https://api-new.dormint.io"
  : "https://api2.dormint.io";
export const IsUnderMaintenance = false;
export const BOT_NAME = IS_RELEASE ? "dormint_bot" : "dormint_staging_bot";
export const SHOW_CLOSE_ON_FORCE_QUEST_TIMEOUT = 5000;
export const ADS_ENCRYPT_KEY = "84630783-f00e-46";
export const BANNER_TIMEOUT = IS_RELEASE ? 50 : 10;
export const GA_TRACKING_ID = "G-MEJ5GGTTLM";

export const BETA_TESTERS = [5037524328, 171439040, 421736376];

export const MONTHLY_VPN_COST = 450;
export const MONTHLY_3_VPN_COST = 1000;

export const VPN_URLS = {
  iOS: "https://apps.apple.com/app/id1506797696",
  Android: "https://play.google.com/store/apps/details?id=com.vpn.client",
  Linux: "https://vpnclient.app/current/dormint/dormint.run",
  MacOS: "https://vpnclient.app/current/dormint/dormint.dmg",
  Windows: "https://vpnclient.app/current/dormint/dormint.exe",
};

export const NOT_ENOUGH_DAILY_QUOTE_LOOTBOXES_ERROR =
  "not_enough_daily_quote_lootboxes_error";
export const NOT_ENOUGH_SLEEPCOINS_ERROR = "not_enough_sleepcoins_error";

export const encryptAdsToken = (token: string) => {
  return CryptoJS.AES.encrypt(token, CryptoJS.enc.Utf8.parse(ADS_ENCRYPT_KEY), {
    mode: CryptoJS.mode.ECB,
  }).toString();
};

export interface coinType {
  title: string;
  icon: ReactElement;
}

export const coinIcon = (
  coin: string,
  width: string,
  height: string,
  color?: string
) => {
  switch (coin) {
    case "DORM":
      return <Dorm width={width} height={height} color={color!} />;
    case "Sleepcoin":
      return <img src={Sleepcoin} width={width} height={height} />;
    case "sleepcoin":
      return <img src={Sleepcoin} width={width} height={height} />;
    case "sleepcoins":
      return <img src={Sleepcoin} width={width} height={height} />;
    case "lootboxes":
      return <img src={LootBox} width={width} height={height} />;
    case "MATIC":
      return <Matic width={width} height={height} color={color!} />;
    case "money":
      return <img src={DollarCoin} width={width} height={height} />;
    case "minicoin":
      return <img src={MiniCoin} width={width} height={height} />;
    case "tgstar":
      return <img src={TgStar} width={width} height={height} />;
    case "toncoin":
      return <img src={TonCoin} width={width} height={height} />;
    case "TON":
      return <img src={TonCoin} width={width} height={height} />;
  }
};

export interface transactioninterface {
  coin: string;
  status: string;
  date: string;
  sum: string;
}

export interface QuestInterface {
  quest_id: number;
  icon: string;
  name: string;
  reward: number;
  url: string;
  priority: number;
  check_url: null | string;
  status:
    | "quest_completed"
    | "quest_newly_completed"
    | "quest_newly_not_completed"
    | "quest_waiting"
    | "quest_not_completed";
  quest_type: string;
}

export interface UserInfoInterface {
  email: string;
  name: string;
  sleepcoin_balance: number;
  dorm_balance: number;
  eth_token: string;
  discord_username: string;
  twitter_username: string;
  status: string;
}

export interface FarmingDataInterface {
  farming_status:
    | "farming_status_not_started"
    | "farming_status_started"
    | "farming_status_finished"
    | "farming_bad_status_error";
  farming_value: number;
  farming_speed: number;
  farming_left: number;
}

export interface FrenInterface {
  name: string;
  balance: number;
  claim_secret: string;
}

export interface FrensDataInterface {
  available: number;
  frens: Array<FrenInterface>;
  invitation_limit: number;
  invite_token: string | undefined;
  total_frens: number;
}

export const QUEST_WAITING = "quest_waiting";
export const QUEST_NEWLY_COMPLETED = "quest_newly_completed";
export const QUEST_NEWLY_NOT_COMPLETED = "quest_newly_not_completed";
export const QUEST_COMPLETED = "quest_completed";
export const QUEST_NOT_COMPLETED = "quest_not_completed";
export const QUEST_WAIT_SECONDS = 10;

export const placeImage = (
  place: number | undefined,
  width: string,
  height: string
) => {
  switch (place) {
    case 0:
      return (
        <img
          style={{ margin: "0 -2px" }}
          src={KingPlaceIcon}
          width={width}
          height={height}
        />
      );
    case 1:
      return (
        <img
          style={{ margin: "0 -2px" }}
          src={FirstPlaceIcon}
          width={width}
          height={height}
        />
      );
    case 2:
      return (
        <img
          style={{ margin: "0 -2px" }}
          src={SecondPlaceIcon}
          width={width}
          height={height}
        />
      );
    case 3:
      return (
        <img
          style={{ margin: "0 -2px" }}
          src={ThirdPlaceIcon}
          width={width}
          height={height}
        />
      );
    default:
      return (
        <span
          style={{
            width: width,
            height: height,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 -2px",
          }}
        >
          {place}
        </span>
      );
  }
};

export interface LeaderboardPlayerInterface {
  invited: number;
  reward: number;
  place: number;
  username: string;
}

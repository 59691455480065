import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API, FarmingDataInterface, coinIcon } from "../../constants";
import styles from "./FarmingCard.module.scss";
import pillows_texts from "./pillows_texts.json";
import store from "../../store";
import {
  startSleeping,
  claimSleepReward,
  sleepingProccess,
  sleepcoinBalanceVerification,
} from "../../store/slices/data";
import background_image from "../../assets/images/general_bg.png";
import GradientSVG from "./GradientSVG";
import { RoundSlider } from "mz-react-round-slider";
import mini_coin from "../../assets/images/mini-coin.png";
import { useAuthToken } from "../../hooks/useAuthToken.tsx";
import duck from "../../assets/images/duck.png";
import { useNavigate } from "react-router-dom";
// import LootboxIcon from "../../assets/icons/LootboxIcon.tsx";
// import { useNavigate } from "react-router-dom";
/*
import {useAdsgram} from "../../ads/hooks/useAdsgram.ts";
import {ShowPromiseResult} from "../../ads/types/adsgram";
*/

function FarmingCard({
  farmingData,
  sleepcoinBalance,
  healthrate,
}: {
  farmingData: FarmingDataInterface;
  sleepcoinBalance: number;
  healthrate: number;
}) {
  // const navigate = useNavigate();
  const authToken = useAuthToken();

  function secondsToHms(seconds: number, type: "farming" | "lootbox") {
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor((seconds % 3600) % 60);

    return type === "farming"
      ? h <= 0 && m <= 0
        ? "< 1 m"
        : `${h}h ${m}m`
      : `${h}:${m}:${s}`;
  }

  const [lootbox_left_time, setLootbox_left_time] = useState(10000);

  const [error, setError] = useState(false);
  const [animate, setAnimate] = useState<"open" | "close">("open");

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setAnimate("close");
      }, 2000);
      setTimeout(() => {
        setError(false);
        window.location.reload();
      }, 2500);
    }
  }, [error]);

  const navigate = useNavigate();

  function startFaming() {
    axios
      .post(`${API}/tg/farming/start`, {
        auth_token: authToken,
      })
      .then((resp) => {
        if (resp.data.status === "ok") {
          store.dispatch(
            startSleeping({
              farming_time: resp.data.farming_time,
              farming_speed: resp.data.farming_speed,
            })
          );
          store.dispatch(
            sleepcoinBalanceVerification(resp.data.sleepcoin_balance)
          );
        } else {
          setError(true);
        }
      })
      .catch(() => {
        navigate("/error");
      });
  }

  function claimReward() {
    axios
      .post(`${API}/tg/farming/claimed`, {
        auth_token: authToken,
      })
      .then((resp) => {
        if (resp.data.status === "ok") {
          store.dispatch(claimSleepReward());
          store.dispatch(
            sleepcoinBalanceVerification(resp.data.sleepcoin_balance)
          );
        } else {
          setError(true);
        }
      })
      .catch(() => {
        navigate("/error");
      });
  }

  useEffect(() => {
    if (farmingData?.farming_status === "farming_status_started") {
      const timer = setInterval(() => {
        store.dispatch(sleepingProccess());
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [farmingData?.farming_status]);

  useEffect(() => {
    if (lootbox_left_time > 0) {
      const timer = setInterval(() => {
        setLootbox_left_time((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [lootbox_left_time]);

  function getRandom(len: number) {
    return Math.floor(Math.random() * len);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statusData, _setStatusData] = useState({
    farming_status_not_started: {
      title: "Start sleeping",
      text: pillows_texts.before_sleep.texts[
        getRandom(pillows_texts.before_sleep.texts.length)
      ],
      color: "#fff",
      background: "linear-gradient(180deg, #6B7AFF 0%, #4940F2 100%)",
      pillow:
        pillows_texts.wake.pillows[
          getRandom(pillows_texts.wake.pillows.length)
        ],
    },
    farming_status_started: {
      title: "Farming",
      text: pillows_texts.sleep.texts[
        getRandom(pillows_texts.sleep.texts.length)
      ],
      color: "#fff",
      background: "#2D2F54",
      pillow:
        pillows_texts.sleep.pillows[
          getRandom(pillows_texts.sleep.pillows.length)
        ],
    },
    farming_status_finished: {
      title: "Claim",
      text: pillows_texts.wake.texts[
        getRandom(pillows_texts.wake.texts.length)
      ],
      color: "#fff",
      background: "linear-gradient(180deg, #6B7AFF 0%, #4940F2 100%)",
      pillow:
        pillows_texts.sleep.pillows[
          getRandom(pillows_texts.sleep.pillows.length)
        ],
    },
    farming_bad_status_error: {
      title: "Some problems...",
      text: "It’s recommended to sleep at least 7-8 hours",
      color: "#fff",
      background: "#00CC52",
      pillow:
        pillows_texts.wake.pillows[
          getRandom(pillows_texts.wake.pillows.length)
        ],
    },
  });

  const showHealthrate = true;
  // const [showHealthrate, setShowHealthrate] = useState(false);
  /*
  function handleShowHealthrate() {
    setShowHealthrate(true);
    setTimeout(() => {
      setShowHealthrate(false);
    }, 2000);
  }
   */

  const [showSubInfoAlert, setShowSubInfoAlert] = useState(false);

  const subInfoRef = useRef<HTMLInputElement | null>(null);
  const circleRef = useRef<HTMLInputElement | null>(null);

  const [top, setTop] = useState<number>(0);
  const [circleBottom, setCircleBottom] = useState<number>(0);

  useEffect(() => {
    if (subInfoRef.current) {
      setTop(subInfoRef.current.offsetTop);
    }
    if (circleRef.current) {
      setCircleBottom(
        circleRef.current.offsetTop + circleRef.current.offsetHeight
      );
    }
  }, []);

  function handleShowSubInfoAlert() {
    setShowSubInfoAlert(true);
    setTimeout(() => {
      setShowSubInfoAlert(false);
    }, 2000);
  }

  /*
 const onReward = useCallback(() => {
   claimReward()
 }, []);

 const onError = useCallback((result: ShowPromiseResult) => {
   console.log("Error: ", result)
 }, []);

 const showAd = useAdsgram({ blockId: "1162",
   onReward,
   onError });

 */

  const [showDuck, setShowDuck] = useState(false);

  useEffect(() => {
    const randomValue = Math.random();
    if (randomValue < 0.0001) {
      setShowDuck(true);
    }
  }, []);

  return (
    <>
      {error && (
        <div
          className={
            styles[`${animate === "open" ? "alert_open" : "alert_close"}`]
          }
        >
          Something went wrong!
        </div>
      )}
      {showDuck && <img className={styles.duck} src={duck} alt="duck" />}
      <div className={styles.farmingCard}>
        <img className={styles.bg_img} src={background_image} alt="" />
        <div className={styles.farmingInfo}>
          <div
            ref={circleRef}
            style={{
              cursor: "pointer",
              position: "absolute",
              transform: "scale(-1, 1) rotate(-90deg)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "fit-content",
              width: "fit-content",
              top: "-20px",
              zIndex: "0",
            }}
            //onClick={handleShowHealthrate}
          >
            <GradientSVG />
            <RoundSlider
              pathThickness={15}
              pathRadius={82}
              pointers={[{ value: healthrate }]}
              disabled={true}
              pathBgColor={
                healthrate !== 100 ? "#353963" : `url(#${"gradient"})`
              }
              connectionBgColorDisabled={`url(#${"gradient"})`}
              pointerRadius={15}
              hideText={true}
              pointerSVG={
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <circle cx="7.2078" cy="7.07951" r="6.95829" fill="#585BF8" />
                  <path
                    d="M7.20782 3.38293C5.16625 3.38293 3.51123 5.03796 3.51123 7.07953C3.51123 9.1211 5.16625 10.7761 7.20782 10.7761C9.12902 10.7761 10.7077 9.31068 10.8874 7.43646C10.8958 7.34924 10.8509 7.26551 10.7737 7.22409C10.6965 7.18267 10.602 7.19165 10.5339 7.24686C10.1601 7.55024 9.68396 7.73187 9.16484 7.73187C7.96392 7.73187 6.99038 6.75833 6.99038 5.5574C6.99038 4.84413 7.33357 4.21107 7.86477 3.8142C7.935 3.76173 7.96685 3.67221 7.94555 3.58717C7.92424 3.50212 7.85395 3.43819 7.76727 3.42503C7.58471 3.3973 7.39787 3.38293 7.20782 3.38293Z"
                    fill="white"
                  />
                </svg>
              }
            />
            <div className={styles.dashedCircle} />
          </div>
          <div
            className={styles.healthrateAlert}
            style={{ opacity: showHealthrate ? 1 : 0 }}
          >
            <h3>{healthrate}%</h3>
            <span>Health rate</span>
          </div>
          <img
            style={{
              transition: "all 1s ease-in-out",
              height: "32vw",
              width: "32vw",
              marginTop: "15px",
            }}
            src={
              farmingData
                ? `https://cdn.dormint.io/WebAppPillows/${
                    statusData[farmingData?.farming_status].pillow
                  }`
                : ""
            }
            alt="pilow"
          />
          <div
            className={styles.description}
            style={{ marginTop: circleBottom * -2 + "px" }}
          >
            {farmingData && statusData[farmingData?.farming_status].text}
          </div>
        </div>
        <div className={styles.userBalance}>
          <span>Sleepcoin</span>
          {coinIcon("Sleepcoin", "22px", "22px")}
          <div className={styles.balance}>{sleepcoinBalance.toFixed(1)}</div>
        </div>
        <div
          className={styles.subInfoAlert}
          style={{ opacity: showSubInfoAlert ? 1 : 0, top: top - 66 }}
        >
          {healthrate === 100 ? (
            <>
              You'll get <span>100%</span> of the usual tokens for the next
              sleep cycle for being consistent. Good job!
            </>
          ) : (
            <>
              You'll get <span>{healthrate}%</span> of the usual tokens for the
              next sleep cycle. Come on, you can do better!
            </>
          )}
        </div>
        <div
          className={styles.farmingSubInfo}
          ref={subInfoRef}
          onClick={handleShowSubInfoAlert}
        >
          <img src={mini_coin} width="10px" height="10px" />
          <div>+{(farmingData.farming_speed * 8 * 3600).toFixed(1)}</div>
          <span>X{(healthrate / 100).toFixed(2)}</span>
          <p>Per cycle</p>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => {
              farmingData?.farming_status === "farming_status_not_started"
                ? startFaming()
                : farmingData?.farming_status === "farming_status_finished" &&
                  claimReward();
            }}
            className={styles.farmingButton}
            style={{
              color: farmingData
                ? statusData[farmingData?.farming_status].color
                : "white",
              background: farmingData
                ? statusData[farmingData?.farming_status].background
                : "blue",
            }}
          >
            {farmingData?.farming_status === "farming_status_not_started" ? (
              <span>{statusData[farmingData.farming_status].title}</span>
            ) : farmingData?.farming_status === "farming_status_started" ? (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "7.5px 15px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 450,
                      lineHeight: "14.63px",
                      color: "rgba(255, 255, 255, 0.4)",
                    }}
                  >
                    {statusData[farmingData.farming_status].title}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      color: "#fff",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <img src={mini_coin} width="10px" height="10px" />
                      {farmingData.farming_value.toFixed(2)}
                    </div>
                    <div>
                      {secondsToHms(farmingData.farming_left, "farming")}
                    </div>
                  </div>
                </div>
              </span>
            ) : (
              farmingData?.farming_status === "farming_status_finished" && (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  {statusData[farmingData.farming_status].title}
                  <img src={mini_coin} width="14px" height="14px" />
                  {farmingData.farming_value.toFixed(3)}
                </span>
              )
            )}
          </button>
          {/*<div
            className={styles.lootbox}
            onClick={() => {
              lootbox_left_time <= 0 && navigate("/lootbox");
            }}
            style={{
              cursor: lootbox_left_time <= 0 ? "pointer" : "default",
            }}
          >
            <LootboxIcon width={24} height={24} />
            {lootbox_left_time > 0 && (
              <span>{secondsToHms(lootbox_left_time, "lootbox")}</span>
            )}
          </div>*/}
        </div>
      </div>
    </>
  );
}

export default FarmingCard;

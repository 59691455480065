import styles from "./Frens.module.scss";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { API, BOT_NAME, FrenInterface } from "../../constants";
import Loader from "../../components/Loader";
import { WebAppContext } from "../../contexts/WebAppContexts";
import store, { RootState } from "../../store";
import { useSelector } from "react-redux";
import {
  addSleepcoinBalance,
  DataState,
  setFrens,
} from "../../store/slices/data";
// import {Dispatch, ThunkDispatch, UnknownAction} from "@reduxjs/toolkit";
import copyIcon from "../../assets/images/copy.png";
import frens_bg from "../../assets/images/general_bg.png";
import frensInfo from "../../assets/images/frens_info.png";
import mini_coin from "../../assets/images/mini-coin.png";
import ProfileAvatar from "../../components/ProfileAvatar";
import { useAuthToken } from "../../hooks/useAuthToken.tsx";
import InfiniteScroll from "react-infinite-scroller";
import PageHeader from "../../components/PageHeader/index.tsx";
import RedErrorIcon from "../../assets/icons/RedErrorIcon.tsx";
import GreeenDoneIcon from "../../assets/icons/GreenDoneIcon.tsx";
import { useNavigate } from "react-router-dom";

function Frens() {
  const authToken = useAuthToken();
  const data = useSelector<RootState>((state) => state.data) as DataState;

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [text, setText] = useState("COPY LINK");
  const { WebApp } = useContext(WebAppContext);

  function generateInviteUrl() {
    return `https://t.me/${BOT_NAME}/${BOT_NAME}?startapp=${data.frensData?.invite_token}`;
  }

  function generateInviteLink() {
    const url = generateInviteUrl();
    const text =
      "\nJoin Dormint with me and let's get some Sleepcoins together! 🌙";
    return `https://t.me/share/url?url=${url}&text=${text}`;
  }

  const [animate, setAnimate] = useState<"open" | "close">("open");

  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    if (data.frensData === null && authToken !== null) {
      loadMore();
    }
  }, [data.frensData, authToken]);

  const navigate = useNavigate();

  function claimReward(idx: number, claimToken: string) {
    axios
      .post(`${API}/tg/frens/claimed`, {
        auth_token: authToken,
        claim_token: claimToken,
      })
      .then((resp) => {
        if (resp.data.status === "ok" && data.frensData) {
          const balance = data.frensData.frens[idx].balance;
          store.dispatch(addSleepcoinBalance(balance));

          const temp = data.frensData
            ? data.frensData.frens.map((fren, i) => {
                if (i === idx) {
                  return { balance: 0, claim_secret: "", name: fren.name };
                } else {
                  return fren;
                }
              })
            : [];
          store.dispatch(setFrens({ frens: temp }));
        } else {
          setError(true);
        }
        setWaiting(false);
        setSuccess(true);
      })
      .catch(() => {
        navigate("/error");
      });
  }

  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [animateCopyAlert, setAnimateCopyAlert] = useState(true);

  function handleShowCopyAlert() {
    setAnimateCopyAlert(true);
    setShowCopyAlert(true);
    setTimeout(() => {
      setAnimateCopyAlert(false);
    }, 1500);
    setTimeout(() => {
      setShowCopyAlert(false);
    }, 2000);
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setAnimate("close");
      }, 2000);
      setTimeout(() => {
        setError(false);
      }, 2500);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setAnimate("close");
      }, 2000);
      setTimeout(() => {
        setSuccess(false);
      }, 2500);
    }
  }, [success]);

  const [fetch, setFetch] = useState(true);

  function loadMore() {
    if (fetch && authToken !== null) {
      axios
        .post(`${API}/tg/frens/invited`, {
          auth_token: authToken,
          offset: data.frensData?.frens.length,
          limit: 10,
        })
        .then((resp) => {
          if (resp.data.status !== "ok") {
            window.location.reload();
          }
          if (data.frensData === null) {
            const temp: Array<FrenInterface> = [];
            resp.data.frens.map(
              (obj: {
                name: string;
                balance: number;
                claim_secret: string;
              }) => {
                temp.push(obj);
              }
            );
            store.dispatch(setFrens({ frens: temp }));
            store.dispatch(
              setFrens({ invitation_limit: resp.data.invitation_limit })
            );
            store.dispatch(setFrens({ total_frens: resp.data.total_frens }));
            store.dispatch(setFrens({ invite_token: resp.data.invite_token }));
          } else if (resp.data.frens.length !== 0) {
            const temp: Array<FrenInterface> = [...data.frensData.frens];
            resp.data.frens.map(
              (obj: {
                name: string;
                balance: number;
                claim_secret: string;
              }) => {
                temp.push(obj);
              }
            );
            store.dispatch(setFrens({ frens: temp }));
          }
          if (resp.data.frens.length === 0) {
            setFetch(false);
          }
        })
        .catch((err) => {
          console.error(err);
          navigate("/error");
          window.location.reload();
        });
    }
  }

  return !data.frensData ? (
    <Loader />
  ) : (
    <>
      {success && (
        <div
          className={
            styles[`${animateCopyAlert ? "alert_open" : "alert_close"}`]
          }
        >
          <GreeenDoneIcon />
          You've claimed successfully
        </div>
      )}
      {error && (
        <div
          className={
            styles[`${animateCopyAlert ? "alert_open" : "alert_close"}`]
          }
        >
          <RedErrorIcon />
          Something went wrong
        </div>
      )}
      {showCopyAlert && (
        <div
          className={
            styles[`${animateCopyAlert ? "alert_open" : "alert_close"}`]
          }
        >
          Invite link copied!
        </div>
      )}
      <div className={styles.frensPage}>
        <PageHeader />
        <div className={styles.content} id="scrollableDiv">
          <img
            src={frensInfo}
            alt="FAQ"
            style={{ zIndex: "2", height: "auto", width: "80vw" }}
          />
          <img
            src={frens_bg}
            style={{
              width: "100vw",
              position: "absolute",
              top: "15px",
              zIndex: 0,
            }}
            alt=""
          />
          <div className={styles.refsCont}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <span className={styles.refsTitle}>
                {data.frensData?.total_frens} friends
              </span>
            </div>
            <InfiniteScroll
              className={styles.infinityScroll}
              threshold={-208}
              loadMore={loadMore}
              hasMore={fetch}
              useWindow={false}
              getScrollParent={() => document.getElementById("scrollableDiv")}
            >
              {data.frensData?.frens.map((ref, idx) => {
                return (
                  <div className={styles.refDiv} key={idx}>
                    <div className={styles.refInfo}>
                      <ProfileAvatar
                        username={ref.name.slice(1, ref.name.length)}
                        width={32}
                        height={32}
                      />
                      <span>{ref.name}</span>
                    </div>
                    <div className={styles.refInfo} style={{ gap: "4px" }}>
                      <button
                        className={styles.balance}
                        style={{
                          opacity: ref.balance <= 0 ? 0.5 : 1,
                        }}
                        onClick={() => {
                          if (!waiting && ref.balance > 0 && data.frensData) {
                            claimReward(idx, ref.claim_secret);
                            setWaiting(true);
                          }
                        }}
                      >
                        <div>
                          <span>Claim</span>
                          <img src={mini_coin} width="10px" height="10px" />
                          <span>{ref.balance.toFixed(1)} SC</span>
                        </div>
                      </button>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
        <div className={styles.inviteButtons}>
          <button
            onClick={() => {
              setOpenShare(true);
              setAnimate("open");
            }}
            className={styles.inviteButton}
          >
            Invite frens
            <span>
              {(data.frensData &&
                data.frensData?.invitation_limit -
                  data.frensData?.total_frens) + " invites left"}
            </span>
          </button>
          <button
            className={styles.inviteButton}
            style={{ width: "fit-content" }}
            onClick={() => {
              handleShowCopyAlert();
              navigator.clipboard.writeText(generateInviteUrl());
            }}
          >
            <img src={copyIcon} width={20} height={20} alt="" />
          </button>
        </div>
        {openShare && (
          <div
            className={
              styles[`shareMenu${animate === "open" ? "_open" : "_close"}`]
            }
            style={{ height: openShare ? "auto" : 0 }}
          >
            <div
              style={{
                height: "4px",
                width: "40px",
                borderRadius: "16px",
                border: "none",
                background: "#C4C6D4",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "-10px",
              }}
            />
            <span>Invite frens</span>
            <div className={styles.buttons}>
              <button
                className={styles.button}
                style={{
                  background:
                    "linear-gradient(180deg, #6B7AFF 0%, #4940F2 100%)",
                  color: "#fff",
                }}
                onClick={() => {
                  if (data.frensData?.invite_token) {
                    setText("COPIED");
                    navigator.clipboard.writeText(generateInviteUrl());
                    setTimeout(() => {
                      setText("COPY LINK");
                    }, 1000);
                  }
                }}
              >
                {text}
              </button>
              <button
                className={styles.button}
                style={{
                  background: "#fff",
                  color: "#7883FE",
                }}
                onClick={() => {
                  if (data.frensData?.invite_token) {
                    WebApp?.openTelegramLink(generateInviteLink());
                  }
                }}
              >
                SHARE
              </button>
              <button
                className={styles.button}
                onClick={() => {
                  setAnimate("close");
                  setTimeout(() => {
                    setOpenShare(false);
                  }, 500);
                }}
                style={{
                  background: "transparent",
                  color: "#fff",
                }}
              >
                CANCEL
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Frens;

// import { useNavigate} from "react-router-dom";
import styles from "./PageHeader.module.scss";
// import { useAuthToken } from "../../hooks/useAuthToken";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { DataState } from "../../store/slices/data";
import dormint_logo from "../../assets/images/dormint_full_logo.png";


function PageHeader() {
  //const navigate = useNavigate();
  //const authToken = useAuthToken();
 // const [searchParams, _] = useSearchParams();

  const data = useSelector<RootState>((state) => state.data) as DataState;
  const { username } = data;

  return (
    <div className={styles.pageHeader}>
      <img width="100px" src={dormint_logo} alt="dormint profile" />
      <div
        className={styles.profileIcon}
      >
        <span className={styles.username}>@{username}</span>
      </div>
    </div>
  );
}

export default PageHeader;
